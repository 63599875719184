import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { components } from 'react-select';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import { PhoneCodeSelectFormInput } from '@/components/atomic/atoms';
import {
  Button,
  CloseIcon,
  InputField,
  SelectFormInput,
  Text
} from '@/components/atomic/nuclei';
import { HOST_NAME_VALIDATION_CONFIG } from '@/config/common';
import phoneCountryCodes from '@/config/phoneCountryCodes';
import useYupValidator from '@/hooks/yupValidator';
import { isMobileNumberValid } from '@/lib/mobileNumberValidation';
import {
  fetchHostSegmentList,
  onSubmitCreateNewHostHandler
} from '@/services/hostList.service';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const { nameRegex, nameInputError } = HOST_NAME_VALIDATION_CONFIG;

const CustomOption = ({ children, data: { name, code }, ...props }) => (
  <components.Option {...props}>
    {code} {name}
  </components.Option>
);

const CreateHostModal = ({ setOpenAddHostModal, setShowToast }) => {
  const router = useRouter();
  const [hostSegmentList, setHostSegmentList] = useState([]);
  const [hostIdWithMatchingMobile, setHostIdWithMatchingMobile] =
    useState(null);

  useEffect(() => {
    fetchHostSegmentList({
      setHostSegmentList,
      setShowToast
    });
  }, []);

  const yupResolver = useYupValidator(
    yup.object().shape({
      customerName: yup
        .string()
        .trim()
        .matches(nameRegex, nameInputError)
        .required('Host Name is required.'),
      phoneNumber: yup
        .string()
        .test('is-valid-number', 'Invalid phone number', (value, { parent }) =>
          isMobileNumberValid(`${parent.codeId.code}${value}`)
        )
        .required('Phone number is required')
    })
  );

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger
  } = useForm({
    defaultValues: {
      codeId: phoneCountryCodes[0],
      phoneNumber: ''
    },
    mode: 'onBlur',
    resolver: yupResolver
  });

  const { isValid, errors } = useFormState({
    control
  });

  const onSubmit = async ({
    codeId,
    customerName,
    hostSegmentId,
    phoneNumber
  }) =>
    onSubmitCreateNewHostHandler({
      hostIdWithMatchingMobile,
      dataToSubmit: {
        codeId,
        customerName,
        hostSegmentId,
        phoneNumber
      },
      router,
      setHostIdWithMatchingMobile,
      setOpenAddHostModal,
      setShowToast
    });

  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={true}
      size='sm'
    >
      <ModalBody>
        <div className='flex h-screen'>
          <div className='mx-auto relative bg-white rounded-xl w-108 flex gap-3 flex-col self-center p-6'>
            <CloseIcon
              {...{
                className:
                  'absolute right-2 cursor-pointer top-2 flex justify-end',
                icon: 'close-with-border-gray.svg',
                onClick: () => {
                  setOpenAddHostModal(false);
                  router.replace(
                    getPageURL({ pageName: PAGE_NAME.HOST_LIST.label })
                  );
                }
              }}
            />
            <Text
              {...{
                className:
                  'text-center text-nero text-base flex justify-center font-medium',
                content: 'Add New Host'
              }}
            />
            <InputField
              {...{
                className: 'rounded-lg pl-10',
                dbName: 'customerName',
                errors,
                iconURL: 'host-type-black-icon.svg',
                inputGroup: 'flex flex-col gap-2',
                label: 'Host Name *',
                labelClass: 'text-nero text-base font-medium',
                maxLength: 25,
                minLength: 3,
                onChange: (e) => {
                  setValue('customerName', e.target.value);
                },
                onBlur: () => {
                  trigger('customerName');
                },
                placeholder: 'Enter host name',
                register
              }}
            />
            <div>
              <Text
                {...{
                  className: 'text-base font-medium mb-2 mt-2',
                  content: 'Phone Number *'
                }}
              />
              <div className='flex'>
                <PhoneCodeSelectFormInput
                  {...{
                    className: 'phone-code-select text-sm md:text-base w-28',
                    clearErrors,
                    control,
                    CustomOption,
                    dbName: 'codeId',
                    errors,
                    getValues,
                    inputFieldProps: {
                      className:
                        'phone-code-input border border-platinum rounded-r-lg h-10 text-sm md:text-base pl-10',
                      dbName: 'phoneNumber',
                      errors,
                      iconURL: 'phone-icon-black.svg',
                      inputGroup: 'flex flex-1',
                      onChange: (e) => {
                        setValue('phoneNumber', e.target.value);
                        hostIdWithMatchingMobile &&
                          setHostIdWithMatchingMobile(null);
                      },
                      placeholder: 'Enter phone number',
                      register,
                      type: 'number',
                      onBlur: () => {
                        trigger('phoneNumber');
                      }
                    },
                    register,
                    selectContainerClassName: 'phone-code-container',
                    setValue,
                    trigger,
                    width: 'w-20 md:w-20'
                  }}
                />
              </div>
            </div>
            <Text
              {...{
                className: 'text-base font-medium mt-2',
                content: 'Host Type'
              }}
            />
            <SelectFormInput
              {...{
                clearErrors,
                dbName: 'hostSegmentId',
                errors,
                getValues,
                icon: { url: 'host-type-black-icon.svg' },
                placeholderLabel: 'customer type',
                register,
                setValue,
                showIcon: true,
                values: hostSegmentList
              }}
            />
            {hostIdWithMatchingMobile && (
              <div className='self-center'>
                Host Already Exists!
                <Link
                  _blank
                  href={getPageURL({
                    pageName: PAGE_NAME.HOST_EVENT_LIST.label,
                    pathParams: {
                      userId: hostIdWithMatchingMobile
                    }
                  })}
                >
                  <Text
                    className='text-blue px-1 cursor-pointer'
                    content='View Profile'
                    HtmlTag='span'
                  />
                </Link>
              </div>
            )}
            <Button
              {...{
                className:
                  'text-sm font-medium bg-brand-gradient text-white rounded-lg py-3 mt-4',
                disabled: !isValid,
                label: 'Create',
                onClick: handleSubmit(onSubmit)
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreateHostModal;
