import _ from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

import {
  ActionModal,
  CartCardBody,
  CartCardHeader,
  PDFDownloadConfirmationModal
} from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import { BID_STATUS } from '@/config/bid';
import {
  ACTION_MODAL_SOURCE,
  CART_STATUS,
  staticMediaStoreBaseURL
} from '@/config/common';
import copyToClipBoard from '@/lib/copyToClipBoard';
import { useIsMobile } from '@/lib/screenResolution';
import { copyQuoteLink } from '@/services/cart.plan.service';
import { downloadQuotationPDF } from '@/services/invoiceQuotationCommon.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import { getDispatchCTALabel } from '@/services/tender.service';
import {
  cloneCartToUserEvent,
  verifyEventNumberExistence
} from '@/services/userEventCartList.service';

const activeModalsConfig = {
  CLONE_CART: {
    component: ActionModal,
    key: 'CLONE_CART',
    value: null
  },
  PDF_DOWNLOAD_CONFIRMATION: {
    component: PDFDownloadConfirmationModal,
    key: 'PDF_DOWNLOAD_CONFIRMATION',
    value: null
  }
};

const CartCard = ({
  cartCard,
  cartListType,
  router,
  setEditCartSummaryModal,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId,
  userInfo
} = {}) => {
  const {
    cartName,
    cartNumber,
    cartOrder,
    createdAt,
    deliveryDate,
    id,
    isArchived: isArchivedCart,
    opsUser,
    pickupDate,
    status,
    tenders,
    updatedAt
  } = cartCard;
  const userId = userInfo?.id;

  const [activeModal, setActiveModal] = useState(null);

  const cartCreatedBy = opsUser?.name || 'NA';
  const dispatchCTALabel = getDispatchCTALabel({ tenders });

  const togglePDFConfirmationModal = () => setActiveModal(null);

  const onClickCopyQuoteLink = (event) =>
    copyQuoteLink({
      cartId: id,
      copyToClipBoard,
      event,
      setShowPDFDownloadConfirmationModal: togglePDFConfirmationModal,
      setShowToast
    });

  const onClickDownloadQuotationPDF = ({ isProforma }) => {
    togglePDFConfirmationModal();
    downloadQuotationPDF({
      cartId: id,
      isProforma,
      isStandardTypeSelected: true,
      userCartId: userInfo?.id
    });
  };

  const isActiveTenderAvailable = _.some(tenders, {
    status: BID_STATUS.ACTIVE.value
  });

  const isCartInOrderState = status === CART_STATUS.ORDER.value;

  const ActiveModal = () => {
    let ActiveModalJSX = () => <></>;
    let activeModalProps = {};

    if (activeModal) {
      const { key: activeModalKey } = activeModal;
      switch (activeModalKey) {
        case activeModalsConfig.CLONE_CART.key:
          ActiveModalJSX = activeModalsConfig.CLONE_CART.component;
          activeModalProps = {
            actionModalRequest: activeModal.value,
            ctaLabel: 'Export cart',
            entityNameKey: 'eventName',
            onClickVerify: verifyEventNumberExistence,
            onSubmitRequestConfig: {
              parameters: {
                cartNumber,
                setLoading,
                setShowToast
              },
              routine: cloneCartToUserEvent
            },
            placeholder: 'Enter Event Number',
            setActionModalRequest: (requestToUpdate) =>
              setActiveModal({
                key: activeModalsConfig.CLONE_CART.key,
                value: requestToUpdate
              }),
            source: ACTION_MODAL_SOURCE.EXPORT_CART,
            title: 'Enter Event Number to Export Cart'
          };
          break;
        case activeModalsConfig.PDF_DOWNLOAD_CONFIRMATION.key:
          ActiveModalJSX =
            activeModalsConfig.PDF_DOWNLOAD_CONFIRMATION.component;
          activeModalProps = {
            onClickCopyQuoteLink,
            onClickDownloadQuotationPDF,
            togglePDFConfirmationModal
          };
          break;
        default:
          break;
      }
    }
    return <ActiveModalJSX {...{ ...activeModalProps }} />;
  };

  return (
    <div
      className={`py-3 my-5 md:border text-sm md:text-base border-[#F3F3F6] rounded-lg px-4 md:px-3 bg-lightgray font-medium hover:border-cinnabar shadow-event cursor-pointer relative`}
    >
      <CartCardHeader
        {...{
          cartName,
          cartNumber,
          cartOrder,
          status
        }}
      />
      <CartCardBody
        {...{
          activeModalsConfig,
          cartCreatedBy,
          cartListType,
          cartName,
          cartNumber,
          cartOrder,
          createdAt,
          deliveryDate,
          dispatchCTALabel,
          id,
          isActiveTenderAvailable,
          isArchivedCart,
          isCartInOrderState,
          onClickCopyQuoteLink,
          pickupDate,
          router,
          setActiveModal,
          setEditCartSummaryModal,
          setLoading,
          setShowToast,
          setUserEventCartDetails,
          status,
          updatedAt,
          userEventId,
          userId,
          userInfo
        }}
      />
      <ActiveModal />
    </div>
  );
};

const CartList = ({
  cartCardListCommonProps,
  cartListType,
  showCartListHeader = true,
  showControlToCollapseList,
  title,
  userEventCartList
}) => {
  const {
    setEditCartSummaryModal,
    setLoading,
    setShowToast,
    setUserEventCartDetails,
    userEventId,
    userInfo
  } = cartCardListCommonProps;
  const router = useRouter();
  const [isMobile] = useIsMobile();
  const [showCartCardList, setShowCartCardList] = useState(true);

  const onCartCardClick = ({ cartCard: { id, status } }) => {
    const pageName =
      status === CART_STATUS.ORDER.value
        ? PAGE_NAME.ORDERED_CART.label
        : PAGE_NAME.PLANNER_CART.label;
    return router.push(getPageURL({ pageName, pathParams: { cartId: id } }));
  };

  return (
    <div>
      {showCartListHeader && (
        <div className='flex items-center gap-2 mt-6'>
          <Text
            {...{
              className:
                'text-base md:text-2xl font-medium font-Montserrat flex self-center',
              content: title
            }}
          />
          {showControlToCollapseList && (
            <Image
              {...{
                alt: 'show hide details',
                className: `cursor-pointer transform ${
                  showCartCardList ? 'rotate-0' : '-rotate-90'
                }`,
                height: 0,
                onClick: () => setShowCartCardList(!showCartCardList),
                src: `${staticMediaStoreBaseURL}/icons/down-arrow.svg`,
                style: {
                  height: isMobile ? 12 : 14,
                  width: isMobile ? 12 : 14
                },
                width: 0
              }}
            />
          )}
        </div>
      )}
      <div>
        {showCartCardList &&
          userEventCartList.map((cartCard) => (
            <div
              id='cartCard'
              key={cartCard.cartNumber}
              onClick={() => onCartCardClick({ cartCard })}
            >
              <CartCard
                key={cartCard.id}
                {...{
                  cartCard,
                  cartListType,
                  router,
                  setEditCartSummaryModal,
                  setLoading,
                  setShowToast,
                  setUserEventCartDetails,
                  userEventId,
                  userInfo
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CartList;
