import _ from 'lodash';

export const DEFAULT_HITS_PER_PAGE = 10;

export const DEFAULT_FILTER_OPTIONS_LIMIT = 5;

export const SEARCHABLE_ATTRIBUTES = [
  {
    esDocKey: 'orderNumber',
    key: 'ORDER_NUMBER',
    label: 'Order Number',
    sortOrder: 1
  },
  { esDocKey: 'hostName', key: 'HOST_NAME', label: 'Host Name', sortOrder: 2 },
  {
    esDocKey: 'hostMobile',
    key: 'HOST_MOBILE_NUMBER',
    label: 'Host Mobile Number',
    sortOrder: 3
  },
  {
    esDocKey: 'cartNumber',
    key: 'CART_NUMBER',
    label: 'Cart Number',
    sortOrder: 4
  },
  {
    esDocKey: 'userEventNumber',
    key: 'USER_EVENT_NUMBER',
    label: 'User Event Number',
    sortOrder: 5
  },
  {
    esDocKey: 'zendeskTicketNumber',
    key: 'ZENDESK_TICKET_NUMBER',
    label: 'Zendesk Ticket Number',
    sortOrder: 6
  }
]
  .sort((a, b) => a - b)
  .map((a) => _.omit(a, 'sortOrder'));
