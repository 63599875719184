import _ from 'lodash';

import { ORDER_STATUS } from '@/config/common';
import {
  getFormattedDateFromUnixTimestamp,
  getUnixTimestampForOffsetDate
} from '@/lib/time';

const refinementKeyValueLabelMap = {
  'categoryLevels.level_0': {
    keyLabel: 'Category'
  },
  'price.sortPrice': {
    keyLabel: 'Price Range'
  },
  'productOrderStatistics.userEventCount': {
    keyLabel: 'Event Count'
  },
  'productPartners.partner.legalName': {
    keyLabel: 'Partners'
  },
  bookingDateInSeconds: {
    getValueLabel: (refinement) =>
      `${refinement.operator} ${getFormattedDateFromUnixTimestamp({
        unixTimestamp: refinement.value,
        dateFormat: 'Do MMM YYYY'
      })}`,
    keyLabel: 'Booking Date'
  },
  eventDateInSeconds: {
    getValueLabel: (refinement) =>
      `${refinement.operator} ${getFormattedDateFromUnixTimestamp({
        unixTimestamp: refinement.value,
        dateFormat: 'Do MMM YYYY'
      })}`,
    keyLabel: 'Event Date'
  },
  isDiscountAvailable: {
    getValueLabel: () => 'Yes',
    keyLabel: 'Discount Available'
  },
  status: {
    getValueLabel: ({ value }) => ORDER_STATUS[value].label,
    keyLabel: 'Order Status'
  }
};

const transformCurrentRefinementItemsLabel = (items) =>
  items.map((item) => {
    const {
      keyLabel = _.startCase(item.label),
      getValueLabel = (refinement) => refinement.label
    } = refinementKeyValueLabelMap[item.attribute] || {};

    const refinements = item.refinements.map((refinement) => ({
      ...refinement,
      label: getValueLabel(refinement)
    }));

    return { ...item, label: keyLabel, refinements };
  });

export default transformCurrentRefinementItemsLabel;

export const getMenuFromCurrentURL = ({
  attribute,
  index,
  instantSearchRouter,
  setSelectedTab
}) => {
  const urlParams = new URLSearchParams(
    instantSearchRouter.getLocation().search
  );
  const menu = urlParams.get(`${index}[menu][${attribute}]`);
  setSelectedTab(menu);
};

export const getCountForAttribute = ({ attribute, currentRefinements }) => {
  const { refinements: attributeRefinements = [] } =
    currentRefinements.find(
      (refinement) => refinement.attribute === attribute
    ) || {};
  return attributeRefinements.length;
};

export const DATE_RANGE_ITEMS_FOR_EVENT_DATE = {
  ALL: {
    key: 'all',
    label: 'All'
  },
  PAST: {
    end: getUnixTimestampForOffsetDate({ dateOffset: -1 }),
    key: 'past',
    label: 'Past'
  },
  TODAY: {
    end: getUnixTimestampForOffsetDate({ dateOffset: 0, endOfDay: true }),
    key: 'today',
    label: 'Today',
    start: getUnixTimestampForOffsetDate({ dateOffset: 0 })
  },
  TOMORROW: {
    end: getUnixTimestampForOffsetDate({ dateOffset: 1, endOfDay: true }),
    key: 'tomorrow',
    label: 'Tomorrow',
    start: getUnixTimestampForOffsetDate({ dateOffset: 1 })
  },
  UPCOMING: {
    key: 'upcoming',
    label: 'Upcoming',
    start: getUnixTimestampForOffsetDate({ dateOffset: 1 })
  },
  YESTERDAY: {
    end: getUnixTimestampForOffsetDate({ dateOffset: -1, endOfDay: true }),
    key: 'yesterday',
    label: 'Yesterday',
    start: getUnixTimestampForOffsetDate({ dateOffset: -1 })
  }
};

const getFacetFilterValue = ({ url, key }) => {
  const searchParams = new URLSearchParams(url);
  const facetFiltersValue = searchParams.get('facetFilters');
  if (!facetFiltersValue) {
    return null;
  }

  const keyValuePairs = facetFiltersValue
    .split(',')
    .map((pair) => pair.split(':'));
  const value = _.chain(keyValuePairs)
    .find(([currentKey]) => currentKey === key)
    .last()
    .value();
  return value;
};

const transformOrderStatus = ({ items, e }) =>
  Object.values(ORDER_STATUS)
    .map(({ value: userEventStageToInspect, label }) => {
      const itemsOfDeliveryModeToInspect = items.find(
        ({ value: userEventStage }) =>
          userEventStageToInspect === userEventStage
      );
      const isRefined =
        getFacetFilterValue({
          key: 'status',
          url: e.results.params
        }) === userEventStageToInspect;

      const RadioInputLabel = () => (
        <label className='searchkit-filter-menu-item searchkit-order-status-filter-menu searchkit-delivery-menu-item relative cursor-pointer'>
          <input
            className='checked:content-searchkit-filter-menu-item-checked searchkit-radio searchkit-filter-menu-item-checked'
            defaultChecked={isRefined}
            name='status'
            type='radio'
            value={userEventStageToInspect}
          />
          <span className='before:bg-searchkit-filter-menu-item inline-block w-5 h-5 empty-radio-span'></span>
          <span className='text-sm text-dim-gray pl-4 self-center'>
            {label}
          </span>
        </label>
      );

      const defaultBucket = {
        count: 0,
        data: null,
        exhaustive: true,
        isRefined,
        label: <RadioInputLabel />,
        value: userEventStageToInspect
      };

      return itemsOfDeliveryModeToInspect
        ? { ...itemsOfDeliveryModeToInspect, label: <RadioInputLabel /> }
        : defaultBucket;
    })
    .filter(Boolean);

export const transformOrderStatusRadio = () => (props, e) =>
  transformOrderStatus({ items: props, e });
