import { SearchBox } from 'react-instantsearch';

import { ScopeSearchAttributeModal } from '@/components/atomic/atoms';
import { SEARCHABLE_ATTRIBUTES } from '@/config/searchkit/orders';

const getPlaceholderTextForSearchBox = ({
  searchableAttribute,
  searchAttributeLabelList
}) => {
  const filter = searchableAttribute
    ? ({ esDocKey }) => esDocKey === searchableAttribute
    : ({ key }) => key !== 'ALL';

  const suffix = searchAttributeLabelList
    .filter(filter)
    .map(({ label }) => label)
    .join(', ');

  return `Start Typing ${suffix}`;
};

const OrderListSearchBar = ({
  className = 'flex mx-auto relative -top-3',
  searchableAttribute,
  searchAttributeLabelMap = SEARCHABLE_ATTRIBUTES,
  selectedAttributeLabel,
  setSearchableAttribute
}) => (
  <div className='mt-3'>
    <div
      className={`rounded-full shadow-navigate-cart quick-find-box pr-2 h-[52px] border-neutral ${className}`}
    >
      <ScopeSearchAttributeModal
        {...{
          additionalClassForImage: 'mr-4',
          className:
            'flex h-10 rounded-full px-2 border mt-[5px] ml-2 border-brand focus:outline-none text-brand search-type-select cursor-pointer',
          downAngleImage: 'angle-red.svg',
          searchableAttribute,
          searchAttributeLabelMap,
          selectedAttributeLabel,
          setSearchableAttribute
        }}
      />
      <div className='flex'>
        <SearchBox
          {...{
            classNames: {
              form: 'searchkit-searchbox before:bg-searchkit-search-box-form rounded-r-full h-8 md:h-12 w-full md:w-auto',
              input:
                'w-[40rem] rounded-full shadow-none border-none h-[49px] text-base font-medium !text-nero'
            },
            placeholder: getPlaceholderTextForSearchBox({
              searchableAttribute,
              searchAttributeLabelList: searchAttributeLabelMap
            })
          }}
        />
      </div>
    </div>
  </div>
);

export default OrderListSearchBar;
