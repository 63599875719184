import Image from 'next/image';
import { useRef } from 'react';

import { Button } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const filterEmptyClassNamesAndJoin = (classNameStringList) =>
  classNameStringList.filter((className) => className).join(' ');

const SearchSortFilter = ({
  Component,
  componentProps,
  isOpenFilterDrawer
}) => (
  <div
    className={filterEmptyClassNamesAndJoin([
      'w-full md:w-72 max-h-80 overflow-y-auto overflow-x-hidden border shadow-sm border-platinum rounded-md bg-white absolute',
      'z-10 md:z-2 md:mt-11 searchkit-mobile-modal-popup ',
      `${isOpenFilterDrawer ? 'fade-in !block' : 'fade-out'}`
    ])}
  >
    <div className='px-4 py-4'>
      <Component {...componentProps} />
    </div>
  </div>
);

const DynamicFilter = ({
  attribute,
  buttonName,
  Component,
  componentProps,
  count: countOfSelectedRefinement,
  hideFilter,
  icon,
  isOpenFilterDrawer,
  onToggleAttribute,
  selectedIcon
}) => {
  const isFilterAppliedAndDrawerClosed =
    countOfSelectedRefinement > 0 && !isOpenFilterDrawer;

  const filterRef = useRef(null);

  useOnClickOutside(filterRef, () => isOpenFilterDrawer && onToggleAttribute());

  return (
    <div
      className={`relative ${hideFilter ? 'hidden' : 'flex'}`}
      ref={filterRef}
    >
      <div
        className={filterEmptyClassNamesAndJoin([
          'flex gap-2 px-4 py-[9px] rounded-md border shadow-sm hover:border-brand cursor-pointer',
          `${isOpenFilterDrawer ? 'border-brand' : ''}`,
          `${isFilterAppliedAndDrawerClosed ? 'bg-brand-gradient' : 'bg-white'}`
        ])}
        onClick={() => onToggleAttribute(attribute)}
      >
        <div className='w-4 h-4 flex items-center self-center'>
          <Image
            {...{
              alt: isFilterAppliedAndDrawerClosed ? selectedIcon : icon,
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/${isFilterAppliedAndDrawerClosed ? selectedIcon : icon}`,
              style: { height: 20, width: 20 },
              width: 0
            }}
          />
        </div>
        <div className='flex'>
          <Button
            {...{
              className: filterEmptyClassNamesAndJoin([
                'font-medium text-sm text-nowrap',
                `${isOpenFilterDrawer ? 'text-nero' : ''}`,
                `${
                  isFilterAppliedAndDrawerClosed
                    ? 'text-white'
                    : 'text-dim-gray'
                }`
              ]),
              label: buttonName
            }}
          />
        </div>
        <div className='w-3 h-3 flex self-center items-center'>
          <Image
            {...{
              alt: 'down angle',
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/${
                isFilterAppliedAndDrawerClosed
                  ? 'down-angle-white.svg'
                  : 'angle-down.svg'
              }`,
              style: { height: 12, width: 12 },
              width: 0
            }}
          />
        </div>
      </div>
      <SearchSortFilter
        {...{
          Component,
          componentProps,
          isOpenFilterDrawer
        }}
      />
    </div>
  );
};

export default DynamicFilter;
