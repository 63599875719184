import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import {
  AddNewCreditEntityModal,
  HostProfileStepOne,
  HostProfileStepTwo,
  StepFormHeader,
  ViewEditCTAPair
} from '@/components/atomic/atoms';
import ToastContainer from '@/components/atomic/molecules/common/ToastContainer';
import {
  DEFAULT_TOAST_CONFIG,
  HOST_NAME_VALIDATION_CONFIG
} from '@/config/common';
import useYupValidator from '@/hooks/yupValidator';
import {
  fetchStaticAPIs,
  hostProfileStepFieldErrorConfig,
  hostProfileTabs,
  updateHostProfile,
  validateHostCreditEntityId
} from '@/services/hostProfile.service';

const { nameRegex, nameInputError } = HOST_NAME_VALIDATION_CONFIG;

const activeModalsConfig = {
  ADD_NEW_CREDIT_ENTITY: {
    component: AddNewCreditEntityModal,
    key: 'ADD_NEW_CREDIT_ENTITY',
    value: false
  },
  TOAST_CONTAINER: {
    component: ToastContainer,
    key: 'TOAST_CONTAINER',
    value: DEFAULT_TOAST_CONFIG
  }
};

const HostProfileHeader = ({
  fieldsErrorConfig,
  errors,
  formActiveStepId,
  onExitClick,
  setFormActiveStepId,
  showCloseIcon
}) => (
  <StepFormHeader
    {...{
      fieldsErrorConfig,
      errors,
      formActiveStepId,
      onExitClick,
      setFormActiveStepId,
      showCloseIcon,
      tabList: Object.values(hostProfileTabs)
    }}
  />
);

const ViewEditHostProfile = ({
  hostProfile: { user },
  setHostCreditEntityId,
  setHostProfile,
  setLoading,
  setShowToast,
  showToast
}) => {
  const router = useRouter();

  const [activeModal, setActiveModal] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [formActiveStepId, setFormActiveStepId] = useState(
    Object.values(hostProfileTabs)[0].id
  );
  const [hostProfileMetadata, setHostProfileMetadata] = useState({
    ethnicityList: [],
    hostTypeList: [],
    preferredLanguageList: []
  });

  const yupResolver = useYupValidator(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .matches(nameRegex, nameInputError)
        .required('Host Name is required.'),
      hostCreditEntityId: yup
        .string()
        .test(
          'hostCreditEntityIdValidation',
          'Credit Entity Name is mandatory.',
          (value, { parent: { hostSegmentId, hostCreditEntityId } }) =>
            validateHostCreditEntityId({
              hostSegmentId,
              hostCreditEntityId,
              hostTypeList: hostProfileMetadata.hostTypeList
            })
        )
    })
  );

  const {
    clearErrors,
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver,
    values: {
      ...user,
      hostCreditEntityId: user.hostCreditEntity?.id,
      hostCreditEntityName: user.hostCreditEntity?.name
    }
  });

  useEffect(() => {
    fetchStaticAPIs({
      setHostProfileMetadata,
      setShowToast
    });
  }, []);

  const [selectedHostCreditEntityName, setSelectedHostCreditEntityName] =
    useState(watch('hostCreditEntityName') || '');

  const { errors } = useFormState({
    control
  });

  const { interactionStatus, hostNumber, hostCreditEntityId } = user;

  const onExitClick = () => {
    router.back();
  };

  const onSubmit = (dataToUpdate) =>
    updateHostProfile({
      dataToUpdate,
      setHostCreditEntityId,
      setHostProfile,
      setLoading,
      setShowToast
    });

  const onSubmitHandler = isEditable
    ? handleSubmit(onSubmit)
    : () => setIsEditable(true);

  const notificationConfig = {
    errors,
    toastConfig: {
      ...showToast,
      show: showToast.show || !_.isEmpty(errors)
    }
  };
  const ActiveModal = () => {
    let ActiveModalJSX = () => <></>;
    let activeModalProps = {};

    if (activeModal) {
      const { key: activeModalKey, value: activeModalValue } = activeModal;
      switch (activeModalKey) {
        case activeModalsConfig.ADD_NEW_CREDIT_ENTITY.key:
          ActiveModalJSX = activeModalsConfig.ADD_NEW_CREDIT_ENTITY.component;
          activeModalProps = {
            creditEntityModalToggle: () => setActiveModal(null),
            setHostCreditEntityId,
            setSelectedHostCreditEntityName,
            setShowToastContainer: (requestToUpdate) => {
              setActiveModal({
                key: activeModalsConfig.TOAST_CONTAINER.key,
                value: requestToUpdate
              });
            },
            setValue
          };
          break;
        case activeModalsConfig.TOAST_CONTAINER.key:
          ActiveModalJSX = activeModalsConfig.TOAST_CONTAINER.component;
          activeModalProps = {
            setShowToast: (requestToUpdate) =>
              setActiveModal({
                key: activeModalsConfig.TOAST_CONTAINER.key,
                value: requestToUpdate
              }),
            showToast: activeModalValue
          };
          break;
        default:
          break;
      }
    }

    return <ActiveModalJSX {...{ ...activeModalProps }} />;
  };

  return (
    <div
      id={hostProfileTabs.HOST_PROFILE.id}
      className='bg-white mb-7 px-6 pb-10'
    >
      <HostProfileHeader
        {...{
          errors,
          fieldsErrorConfig: hostProfileStepFieldErrorConfig,
          formActiveStepId,
          hostNumber,
          interactionStatus,
          onExitClick,
          setFormActiveStepId,
          showCloseIcon: !isEditable
        }}
      />
      <HostProfileStepOne
        {...{
          clearErrors,
          errors,
          formActiveStepId,
          hostCreditEntityId,
          hostProfileMetadata,
          isEditable,
          onClickAddNewEntity: () =>
            setActiveModal({
              key: activeModalsConfig.ADD_NEW_CREDIT_ENTITY.key,
              value: true
            }),
          register,
          selectedHostCreditEntityName,
          setSelectedHostCreditEntityName,
          setValue,
          watch
        }}
      />
      <HostProfileStepTwo
        {...{
          formActiveStepId,
          hostProfileMetadata,
          isEditable,
          register,
          setValue,
          watch
        }}
      />
      <ViewEditCTAPair
        {...{
          backward: {
            onClick: () => {
              if (isEditable) {
                setIsEditable(false);
                clearErrors();
                reset();
                setSelectedHostCreditEntityName(watch('hostCreditEntityName'));
              } else {
                onExitClick();
              }
            }
          },
          forward: {
            onClick: onSubmitHandler
          },
          notificationConfig,
          type: isEditable ? 'editCTAPair' : 'viewCTAPair'
        }}
      />
      <ActiveModal />
    </div>
  );
};

export default ViewEditHostProfile;
