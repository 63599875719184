import _ from 'lodash';
import { useState } from 'react';

import {
  ActionModal,
  AddToCart,
  AddToCartToaster,
  ProductPriceLabel
} from '@/components/atomic/atoms';
import { Button, InputField, Text } from '@/components/atomic/nuclei';
import { useUIContext } from '@/components/context/UiContext/ManagedUiContext';
import {
  ACTION_MODAL_SOURCE,
  PRODUCT_STATUS,
  PRODUCT_TYPE
} from '@/config/common';
import {
  addToCart,
  addToCartFromSelectCartModal,
  verifyCartNumberForAddItem
} from '@/services/product.service';

const SpinnerInput = ({
  changeQuantity,
  handleOnBlur,
  handleValueChange,
  isPublished,
  quantity
}) => (
  <div className='flex justify-between gap-4'>
    <div className='flex flex-row w-36 rounded-md text-2xl border border-gray-200'>
      <div
        className={`border-r border-gray-200 bg-gray
        h-12 w-full rounded-l-md focus:outline-none flex
        justify-center items-center cursor-pointer`}
        name='decrease'
      >
        <Button
          {...{
            className: `${
              !isPublished ? 'opacity-50 cursor-not-allowed' : ''
            } w-full`,
            label: '-',
            onClick: () => changeQuantity('decrease')
          }}
        />
      </div>
      <InputField
        className='h-12 w-20 text-center focus:outline-none'
        onBlur={handleOnBlur}
        onChange={(e) => handleValueChange(e.target.value)}
        text='number'
        type='number'
        value={quantity}
      />
      <div
        className={`border-l border-gray-200 bg-gray
       h-12 w-full rounded-r-md focus:outline-none
       flex justify-center items-center cursor-pointer`}
        name='increase'
      >
        <Button
          {...{
            className: !isPublished ? 'opacity-50 cursor-not-allowed' : '',
            disabled: !isPublished,
            label: '+',
            onClick: () => changeQuantity('increase')
          }}
        />
      </div>
    </div>
  </div>
);

const ProductPriceBox = ({ product, setShowToast }) => {
  const [quantity, setQuantity] = useState(1);
  const [isInfoBandEnabled, setIsInfoBandEnabled] = useState(true);

  const {
    availableDiscount,
    id: productId,
    maxBookable: maxBookableQuantity,
    price,
    status: productStatus,
    type: productType,
    userCartId
  } = product;

  const { activePlannerCart, updateActivePlannerCart } = useUIContext();

  const [addItemToNewCartRequest, setAddItemToNewCartRequest] = useState(null);

  const maxBookable = maxBookableQuantity || Infinity;
  const isPublished = productStatus === PRODUCT_STATUS.PUBLISHED;

  const handleChangeQuantity = {
    decrease: () => setQuantity((prevQuantity) => prevQuantity - 1),
    increase: () => setQuantity((prevQuantity) => prevQuantity + 1)
  };

  const handleValueChange = (value) => {
    const inputValue = parseInt(value, 10);
    if (inputValue < 0) {
      return;
    }
    setQuantity(inputValue);
  };

  const changeQuantity = (type) => {
    handleChangeQuantity[type]();
  };

  const handleOnBlur = (e) => {
    e.stopPropagation();
    const inputValue = parseInt(e.target.value, 10);
    if (Number.isNaN(inputValue)) {
      setQuantity(1);
    }
  };

  const showSpinnerInput =
    productType === PRODUCT_TYPE.CART && isPublished && maxBookable > 1;

  const showAddToCartToaster = Boolean(
    !addItemToNewCartRequest && !_.isEmpty(activePlannerCart)
  );
  return (
    <>
      <div
        className={`product-price-box w-full md:w-1/3 border md:rounded-lg flex
          flex-col self-start pb-3 px-6 md:p-6 fixed md:sticky md:top-24 bottom-0 bg-white`}
      >
        <div className='hidden md:block'>
          <ProductPriceLabel {...{ availableDiscount, price, productType }} />
        </div>
        {maxBookable > 0 && (
          <Text
            {...{
              className: 'text-sm text-brand mt-2 font-medium hidden md:flex',
              content: 'Excluding VAT'
            }}
          />
        )}
        <div className='flex gap-4 mt-3'>
          {showSpinnerInput && (
            <SpinnerInput
              {...{
                changeQuantity,
                handleOnBlur,
                handleValueChange,
                isPublished,
                quantity
              }}
            />
          )}
          <AddToCart
            {...{
              onClickAddToCart: () => {
                const productDetail = {
                  entityId: productId,
                  entityType: productType,
                  quantity,
                  userCartId,
                  priceId: price.id
                };
                if (activePlannerCart) {
                  addToCart({
                    activePlannerCart,
                    productDetail,
                    setShowToast,
                    updateActivePlannerCart
                  });
                } else {
                  setAddItemToNewCartRequest(productDetail);
                }
                setQuantity(1);
              },
              productStatus
            }}
          />
        </div>
        <ActionModal
          {...{
            actionModalRequest: addItemToNewCartRequest,
            ctaLabel: 'Add to cart',
            entityNameKey: 'cartName',
            onClickVerify: verifyCartNumberForAddItem,
            onSubmitRequestConfig: {
              parameters: {
                productDetail: addItemToNewCartRequest,
                updateActivePlannerCart
              },
              routine: addToCartFromSelectCartModal
            },
            placeholder: 'Enter Cart Number',
            setActionModalRequest: setAddItemToNewCartRequest,
            source: ACTION_MODAL_SOURCE.ADD_ITEM_TO_CART,
            title: 'Enter Cart Number to Add Products'
          }}
        />
        {showAddToCartToaster && (
          <AddToCartToaster
            {...{
              activePlannerCart,
              isInfoBandEnabled,
              onClickFinishCTA: () => {
                updateActivePlannerCart(null);
                setAddItemToNewCartRequest(null);
              },
              setIsInfoBandEnabled
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProductPriceBox;
