import { useState } from 'react';

import {
  CreditEntityCreditHistoryCard,
  PageNotFound
} from '@/components/atomic/atoms';
import { hostCreditEntityProfileTabs } from '@/services/hostCreditEntity.service';

const HostCreditEntityProfileStepThree = ({
  formActiveStepId,
  historicalCreditRequests
}) => {
  const formStepVisibilityClass =
    formActiveStepId === hostCreditEntityProfileTabs.CREDIT_HISTORY.id
      ? 'block'
      : 'hidden';
  const [focusedCreditRequestCardId, setFocusedCreditRequestCardId] =
    useState(null);

  if (historicalCreditRequests.length <= 0) {
    return (
      <div className={formStepVisibilityClass}>
        <PageNotFound
          {...{
            alt: 'no credit records found',
            className: 'py-20',
            description: 'No Credit History',
            iconHeight: 316,
            iconWidth: 368,
            notFoundImage: 'no-credit-found.svg',
            title: ''
          }}
        />
      </div>
    );
  }

  return (
    <div className={`${formStepVisibilityClass} pb-10`}>
      {historicalCreditRequests.map((historicalCreditRequest, index) => (
        <CreditEntityCreditHistoryCard
          key={index}
          {...{
            focusedCreditRequestCardId,
            historicalCreditRequest,
            setFocusedCreditRequestCardId
          }}
        />
      ))}
    </div>
  );
};

export default HostCreditEntityProfileStepThree;
