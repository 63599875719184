import { useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

import { OrderListFilterHeader } from '@/components/atomic/atoms';
import {
  OrderListFilters,
  OrderListSearchBar,
  OrderListSearchResultsPanel
} from '@/components/atomic/molecules';
import { NAVIGATION_INDEX } from '@/config/searchkit/navigation';
import {
  DEFAULT_HITS_PER_PAGE,
  SEARCHABLE_ATTRIBUTES
} from '@/config/searchkit/orders';
import createClient from '@/lib/haflaSearchkitClient';
import { PLANNER_PORTAL_FQDN } from '@/services/connections.service';
import { getAuthToken } from '@/services/identity.service';
import { SEARCH_KIT_ORDERS_SEARCH_API_URL } from '@/services/url.service';

const searchClient = createClient({
  headers: {
    'x-access-token': getAuthToken(),
    'x-origin-domain': PLANNER_PORTAL_FQDN
  },
  url: SEARCH_KIT_ORDERS_SEARCH_API_URL
});

const OrderListOrganism = () => {
  const [searchableAttribute, setSearchableAttribute] = useState(
    SEARCHABLE_ATTRIBUTES[0].esDocKey
  );

  const [query, setQuery] = useState('');
  const [showFilters, setShowFilters] = useState(true);
  const [clearBookingDateFilter, setClearBookingDateFilter] = useState(false);
  const [clearEventDateFilter, setClearEventDateFilter] = useState(false);

  const selectedAttributeLabel = Object.values(SEARCHABLE_ATTRIBUTES).find(
    ({ esDocKey }) => esDocKey === searchableAttribute
  )?.label;

  const restrictSearchableAttributes = searchableAttribute
    ? [searchableAttribute]
    : [];
  return (
    <InstantSearch
      {...{
        future: {
          preserveSharedStateOnUnmount: true
        },
        indexName: NAVIGATION_INDEX,
        searchClient
      }}
    >
      <Configure
        {...{
          hitsPerPage: DEFAULT_HITS_PER_PAGE,
          restrictSearchableAttributes
        }}
      />
      <div className='!mt-4 container mx-auto'>
        <div className='flex gap-8 searchkit-filter-container sticky z-10 top-0 bg-white left-0 py-2'>
          <OrderListFilterHeader
            {...{
              onClickClearAll: () => {
                setClearBookingDateFilter(!clearBookingDateFilter);
                setClearEventDateFilter(!clearEventDateFilter);
              },
              setShowFilters,
              showFilters
            }}
          />
          <OrderListSearchBar
            {...{
              query,
              searchableAttribute,
              searchAttributeLabelMap: SEARCHABLE_ATTRIBUTES,
              selectedAttributeLabel,
              setQuery,
              setSearchableAttribute
            }}
          />
        </div>
        <div
          className={`flex gap-6 ${showFilters ? 'h-[70rem]' : 'h-[65rem]'}`}
        >
          <OrderListFilters
            {...{
              clearBookingDateFilter,
              clearEventDateFilter,
              setClearBookingDateFilter,
              setClearEventDateFilter,
              showFilters
            }}
          />
          <OrderListSearchResultsPanel {...{ query }} />
        </div>
      </div>
    </InstantSearch>
  );
};

export default OrderListOrganism;
