import Image from 'next/image';

import {
  ApplyForCreditBanner,
  HostCreditEntityCreditUtilization,
  LetsPlanFirstEvent
} from '@/components/atomic/atoms';
import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { CREDIT_REQUEST_STATUS } from '@/config/hostCreditEntity';
import { getCreditEntityStatisticsParamaters } from '@/services/hostCreditEntity.service';

const LifeTimeGEV = ({ grossEventValue }) => (
  <TextKeyValuePair
    {...{
      className:
        'flex items-center justify-between w-full bg-brand-gradient py-3 px-4 rounded-lg text-white w-full',
      label: 'Lifetime GEV',
      labelClass: 'font-medium text-sm',
      value: grossEventValue,
      valueClassName: 'font-semibold'
    }}
  />
);

const IconWithTextKeyValuePair = ({ alt, icon, label, value }) => (
  <div className='flex items-center gap-2'>
    <Image
      alt={alt}
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
      style={{ height: 28, width: 28 }}
      width={0}
    />
    <TextKeyValuePair
      {...{
        className: 'flex flex-col !gap-0',
        label: value,
        labelClass: 'text-xl text-brand font-semibold',
        value: label,
        valueClassName: 'text-xs font-medium text-nero'
      }}
    />
  </div>
);

const HostCreditEntityEventStatistics = ({ eventStatistics }) => {
  const {
    averageEventValue,
    bookedEventCount,
    grossEventValue,
    inquiredEventCount,
    marginPercent
  } = getCreditEntityStatisticsParamaters({
    eventStatistics
  });

  const hostCreditEntityEventStatisticsList = [
    bookedEventCount,
    marginPercent,
    averageEventValue
  ];

  return (
    <div className='flex flex-col gap-4 w-full'>
      <div className='bg-entity border border-neutral bg-no-repeat flex flex-1 flex-col bg-cover rounded-xl'>
        <div className='flex flex-col gap-4 justify-center items-center py-4 px-6'>
          <Text
            {...{
              className: 'font-semibold text-xl text-brand',
              content: 'Here’s The Hafla Summary'
            }}
          />
          <LifeTimeGEV {...{ grossEventValue }} />
        </div>
        <div className='flex gap-8 px-6 pb-6 pt-4'>
          <TextKeyValuePair
            {...{
              className:
                'bg-total-event w-[140px] h-[140px] flex flex-col px-4 text-center justify-center items-center gap-0',
              label: inquiredEventCount.value,
              labelClass: 'text-brand font-semibold text-[28px] leading-6',
              value: inquiredEventCount.label,
              valueClassName: 'text-sm text-nero font-medium leading-4'
            }}
          />
          <div className='flex gap-4 flex-col'>
            {hostCreditEntityEventStatisticsList.map((item, index) => (
              <IconWithTextKeyValuePair
                key={index}
                {...item}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const HostCreditEntitySideBarBody = ({
  applyForCreditBannerClass,
  createdAt,
  creditFacilityInformation,
  creditUtilizationStyle,
  eventStatistics,
  hostCreditEntityCreditRequests = [],
  letsPlanFirstEvent: { show: showLetsPlanFirstEvent, labelClassName }
}) => {
  const isApprovedCreditRequestExist = hostCreditEntityCreditRequests.some(
    ({ status }) => [CREDIT_REQUEST_STATUS.APPROVED.value].includes(status)
  );

  return (
    <div className='w-[27rem]'>
      {showLetsPlanFirstEvent ? (
        <LetsPlanFirstEvent
          {...{
            createdAt,
            description: 'Welcome onboard at Hafla!',
            labelClassName,
            title: 'Credit Entity Since'
          }}
        />
      ) : (
        <HostCreditEntityEventStatistics
          {...{
            createdAt,
            eventStatistics
          }}
        />
      )}
      {isApprovedCreditRequestExist ? (
        <HostCreditEntityCreditUtilization
          {...{
            creditFacilityInformation,
            creditUtilizationStyle
          }}
        />
      ) : (
        <ApplyForCreditBanner
          {...{
            className: applyForCreditBannerClass.bannerStyle,
            labelStyle: `font-semibold ${applyForCreditBannerClass.labelStyle}`,
            valueStyle: `font-medium ${applyForCreditBannerClass.valueStyle}`
          }}
        />
      )}
    </div>
  );
};

export default HostCreditEntitySideBarBody;
