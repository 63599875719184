import {
  HierarchicalMenu,
  RefinementList,
  ToggleRefinement
} from 'react-instantsearch';

import {
  FilterHeading,
  RangeSliderRefinement
} from '@/components/atomic/atoms';
import { DEFAULT_FILTER_OPTIONS_LIMIT } from '@/config/searchkit/productSearch';

const classNameGroup = {
  checkbox:
    'content-searchkit-checkbox searchkit-checkbox checked:content-searchkit-checkbox-checked',
  count: 'bg-brand text-white border-none font-medium text-xs',
  labelText:
    'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
  list: 'flex flex-col gap-4 max-h-80 overflow-auto',
  searchBox: 'searchkit-search-box searchkit-icon',
  searchableForm: 'searchkit-search-box-form',
  selectedItem: 'searchkit-selected-item',
  showMore: 'searchkit-filter-show-more-button'
};

const DynamicFilter = ({
  componentProps,
  Component,
  headerProps,
  showFilter = true
}) =>
  showFilter && (
    <div>
      <FilterHeading {...headerProps} />
      <Component {...componentProps} />
    </div>
  );

const ProductListFilters = ({ showFilters }) => (
  <div
    className={`${showFilters ? 'visible' : 'hidden'} w-[28rem] flex flex-col sticky top-10 h-[89vh] filter-scroll overflow-x-hidden overflow-y-auto`}
  >
    <div className='w-80 hidden md:block mb-10'>
      <div className='searchkit-filter-container pl-5'>
        <DynamicFilter
          {...{
            componentProps: {
              attribute: 'coreVertical',
              classNames: {
                ...classNameGroup,
                list: classNameGroup.list,
                root: 'searchkit-search-box-event-type'
              },
              limit: DEFAULT_FILTER_OPTIONS_LIMIT,
              showMore: true,
              sortBy: ['name:asc']
            },
            Component: RefinementList,
            headerProps: {
              heading: 'Core Verticals',
              includedAttributes: 'coreVertical'
            }
          }}
        />
        <div className='border-t border-platinum mt-3 mb-4'></div>
        <DynamicFilter
          {...{
            componentProps: {
              attributes: [
                'categoryLevels.level_0',
                'categoryLevels.level_1',
                'categoryLevels.level_2'
              ],
              classNames: {
                ...classNameGroup,
                childList: '!mt-2',
                label:
                  'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
                list: 'flex flex-col gap-3',
                root: 'searchkit-search-box-event-type max-h-96'
              }
            },
            Component: HierarchicalMenu,
            headerProps: {
              heading: 'Category',
              includedAttributes: [
                'categoryLevels.level_0',
                'categoryLevels.level_1',
                'categoryLevels.level_2'
              ]
            }
          }}
        />
        <div className='border-t border-platinum mt-3 mb-4'></div>
        <DynamicFilter
          {...{
            componentProps: {
              attribute: 'productPartners.partner.legalName',
              classNames: {
                ...classNameGroup
              },
              limit: DEFAULT_FILTER_OPTIONS_LIMIT,
              searchable: true,
              searchablePlaceholder: 'Search',
              showMore: true,
              sortBy: ['isRefined', 'name:asc']
            },
            Component: RefinementList,
            headerProps: {
              heading: 'Partners',
              includedAttributes: 'productPartners.partner.legalName'
            }
          }}
        />
        <div className='border-t border-platinum mt-3 mb-4'></div>
        <DynamicFilter
          {...{
            componentProps: {
              attribute: 'productOrderStatistics.userEventCount',
              title: 'Event Range'
            },
            Component: RangeSliderRefinement,
            headerProps: {
              heading: 'Event Count Range',
              includedAttributes: 'productOrderStatistics.userEventCount'
            }
          }}
        />
        <div className='border-t border-platinum mt-5 mb-4'></div>
        <DynamicFilter
          {...{
            componentProps: {
              attribute: 'price.sortPrice',
              title: 'Price Range',
              headerPrefix: 'AED'
            },
            Component: RangeSliderRefinement,
            headerProps: {
              heading: 'Price Value Range',
              includedAttributes: 'price.sortPrice'
            }
          }}
        />
        <div className='border-t border-platinum mt-5 mb-4'></div>
        <DynamicFilter
          {...{
            componentProps: {
              attribute: 'isDiscountAvailable',
              label: 'Discounted Products Only',
              classNames: {
                checkbox: 'checked:!bg-brand !shadow-none !bg-platinum',
                labelText: 'text-sm text-dim-gray font-medium'
              }
            },
            Component: ToggleRefinement,
            headerProps: {
              heading: 'Discount',
              includedAttributes: 'isDiscountAvailable'
            },
            showFilter: false
          }}
        />
        <DynamicFilter
          {...{
            componentProps: {
              attribute: 'type',
              classNames: {
                ...classNameGroup
              }
            },
            Component: RefinementList,
            headerProps: {
              heading: 'Product Type',
              includedAttributes: 'type'
            }
          }}
        />
        <div className='border-t border-platinum mt-3 mb-4'></div>
        <DynamicFilter
          {...{
            componentProps: {
              attribute: 'status',
              classNames: {
                ...classNameGroup
              },
              limit: DEFAULT_FILTER_OPTIONS_LIMIT,
              searchablePlaceholder: 'Search',
              showMore: true
            },
            Component: RefinementList,
            headerProps: {
              heading: 'Status',
              includedAttributes: 'status'
            },
            showFilter: false
          }}
        />
      </div>
    </div>
  </div>
);

export default ProductListFilters;
