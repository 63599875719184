import Image from 'next/image';
import { useRouter } from 'next/router';

import { HostCreditEntityName } from '@/components/atomic/atoms';
import { HostCreditEntitySideBarBody } from '@/components/atomic/molecules';
import {
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const ProfileCTA = ({ id, router }) => (
  <TextWithIcon
    {...{
      alt: 'view profile',
      className:
        'flex items-center gap-2 border border-brand py-1 rounded-lg cursor-pointer px-2',
      icon: 'view-red.svg',
      iconHeight: 16,
      iconWidth: 16,
      label: 'Credit Entity Profile',
      labelStyle: 'text-sm font-medium text-brand',
      onClick: () => {
        router.push(
          getPageURL({
            pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_PROFILE.label,
            pathParams: {
              hostCreditEntityId: id
            }
          })
        );
      }
    }}
  />
);

const AccountManager = ({ accountManager: { name } }) => (
  <div className='flex gap-4 mt-5 mb-10'>
    <Image
      alt='Account Manager'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/planner-icon-orange.svg`}
      style={{ height: 48, width: 48 }}
      width={0}
    />
    <TextKeyValuePair
      {...{
        label: name,
        labelClass: 'text-dim-gray text-lg font-semibold',
        value: 'Account Manager',
        valueClassName: 'text-dim-gray text-sm font-medium',
        className: 'flex-col !gap-0',
        spaceTop: ''
      }}
    />
  </div>
);

const HostCreditEntityHostListSideBarOrganism = ({
  hostCreditEntityCreditRequests,
  hostCreditEntityHostList: {
    hostCreditEntity,
    hostCreditEntityStatistics: { creditFacilityInformation, eventStatistics }
  }
}) => {
  const { accountManager, createdAt, hostCreditEntityNumber, id, name } =
    hostCreditEntity;

  const showLetsPlanFirstEvent = eventStatistics.inquiredEventCount === 0;

  const router = useRouter();

  const tooltipConfigList = [
    {
      target: 'host-credit-entity-number',
      text: 'Credit Entity Number'
    }
  ];

  return (
    <div className='fixed w-108 h-full left-0 bottom-0 bg-white overflow-auto scroll'>
      <div className='bg-white px-8 pb-4 pt-16'>
        <HostCreditEntityName
          {...{
            avatar: {
              name
            },
            label: {
              className: 'text-brand text-xl font-semibold',
              content: name
            }
          }}
        />
        <div className='flex justify-between mt-1'>
          <ProfileCTA {...{ id, router }} />
          <TextWithIcon
            {...{
              alt: 'credit entity number',
              className: 'rounded-lg border border-neutral gap-1 px-2 py-1',
              icon: 'host-credit-entity-red-icon.svg',
              iconHeight: 20,
              iconWidth: 20,
              id: 'host-credit-entity-number',
              label: parseNumberedLabel(hostCreditEntityNumber),
              labelStyle: 'text-dim-gray text-sm font-medium'
            }}
          />
        </div>
        <AccountManager {...{ accountManager }} />
        <HostCreditEntitySideBarBody
          {...{
            applyForCreditBannerClass: {
              bannerStyle: 'bg-credit-entity-utilization',
              labelStyle: 'text-brand',
              valueStyle: 'text-brand text-sm'
            },
            createdAt,
            creditFacilityInformation,
            creditUtilizationStyle: 'bg-brand-gradient',
            eventStatistics,
            hostCreditEntityCreditRequests,
            letsPlanFirstEvent: {
              show: showLetsPlanFirstEvent,
              labelClassName: 'text-brand'
            }
          }}
        />
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default HostCreditEntityHostListSideBarOrganism;
