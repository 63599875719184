import {
  ProductDescription,
  ProductDetail,
  ProductNotes,
  ProductPriceLabel
} from '@/components/atomic/atoms';
import { Divider, TextKeyValuePair } from '@/components/atomic/nuclei';
import { getSerializedRichText } from '@/lib/editor';

const ProductSummaryDetail = ({ product }) => {
  const {
    availableDiscount,
    description,
    displayAttributes,
    name,
    note,
    price,
    shortDescription,
    type: productType
  } = product;

  return (
    <div className='product-info w-full md:w-2/3 px-5 md:pr-20 md:pl-0'>
      <TextKeyValuePair
        {...{
          className: 'flex flex-col gap-4',
          label: name,
          labelClass: 'text-xl md:text-3xl font-semibold text-nero leading-6',
          value: shortDescription,
          valueClassName: 'text-brand text-sm md:text-base font-medium'
        }}
      />
      <div className='block md:hidden my-5'>
        <ProductPriceLabel {...{ availableDiscount, price, productType }} />
      </div>
      <Divider />
      {getSerializedRichText(description) && (
        <ProductDescription {...{ description }} />
      )}
      {displayAttributes.length > 0 && (
        <ProductDetail {...{ displayAttributes }} />
      )}
      {getSerializedRichText(note) && <ProductNotes {...{ note }} />}
    </div>
  );
};

export default ProductSummaryDetail;
