import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Loader, PageNotFound } from '@/components/atomic/atoms';
import { generateMetabaseDashboardSignedAPI } from '@/services/metabase.service';

const fetchMetabaseDashboardSignedURL = async ({
  params = {},
  query = {},
  setMetabaseQuestionURL,
  setLoading
}) => {
  const { entity, status } = await generateMetabaseDashboardSignedAPI({
    query,
    params
  });

  if (status) {
    setMetabaseQuestionURL(entity.url);
  }

  setLoading(false);
};

const MetabaseDashboard = ({
  dashboard: { params = {}, queryParameters = {} }
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [metabaseQuestionURL, setMetabaseQuestionURL] = useState(null);

  useEffect(() => {
    fetchMetabaseDashboardSignedURL({
      params,
      query: queryParameters,
      setMetabaseQuestionURL,
      setLoading
    });
  }, []);

  if (loading || router.isFallback) {
    return <Loader />;
  }

  const finishLoading = () => {
    setLoading(false);
  };

  return (
    <div className='pb-0'>
      {metabaseQuestionURL ? (
        <iframe
          className='dashboard-iframe'
          src={metabaseQuestionURL}
          allowtransparency='true'
          onLoad={finishLoading}
        />
      ) : (
        <PageNotFound />
      )}
    </div>
  );
};

export default MetabaseDashboard;
