import {
  ApprovedCreditRequest,
  PageNotFound,
  PendingCreditRequest
} from '@/components/atomic/atoms';
import { isEmptyOrNull } from '@/lib/utils';
import {
  hostCreditEntityProfileTabs,
  isPlannerCreditFacilityReviewer
} from '@/services/hostCreditEntity.service';

const HostCreditEntityProfileStepTwo = ({
  approvedCreditRequest,
  clearErrors,
  errors,
  formActiveStepId,
  isEditable,
  pendingCreditRequest,
  register,
  setValue,
  showNoActiveCreditRequestBanner,
  watch
}) => {
  const formStepVisibilityClass =
    formActiveStepId === hostCreditEntityProfileTabs.ACTIVE_CREDIT.id
      ? 'block'
      : 'hidden';

  const isCreditFacilityToggleDisabled = !(
    isPlannerCreditFacilityReviewer &&
    isEditable &&
    isEmptyOrNull(pendingCreditRequest)
  );

  if (showNoActiveCreditRequestBanner) {
    return (
      <div className={formStepVisibilityClass}>
        <PageNotFound
          {...{
            alt: 'no active credit found',
            className: 'py-20',
            description: 'No Active Credit',
            iconHeight: 316,
            iconWidth: 368,
            notFoundImage: 'no-credit-found.svg',
            title: ''
          }}
        />
      </div>
    );
  }

  return (
    <div className={formStepVisibilityClass}>
      <div className='flex flex-col gap-4'>
        {!isEmptyOrNull(pendingCreditRequest) && (
          <PendingCreditRequest
            {...{
              clearErrors,
              errors,
              isEditable,
              pendingCreditRequest,
              register,
              setValue,
              watch
            }}
          />
        )}
        {!isEmptyOrNull(approvedCreditRequest) && (
          <ApprovedCreditRequest
            {...{
              approvedCreditRequest,
              isCreditFacilityToggleDisabled,
              register
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HostCreditEntityProfileStepTwo;
